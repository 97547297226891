
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'

  import { MdxLayout } from '@redocly/developer-portal/ui';
import {
  Alert,
  LandingLayout,
  Button,
  Link,
  Box,
  FlexSection,
  Flex,
  Jumbotron,
  Typography,
  ThinTile,
  WideTile,
  Emphasis,
  H1,
  H2,
  SectionHeader,
  NavBar,
} from "@redocly/developer-portal/ui";

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = MdxLayout
  }
  render() {
    const props = this.props
    const components = { wrapper: React.Fragment };


    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)}
             components={components}>


<MDXTag name="h1" components={components} props={{"style":{"position":"relative"}}}><MDXTag name="a" components={components} parentName="h1" props={{"href":"#get-help","aria-label":"get help permalink","className":"anchor before"}}><svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></MDXTag><MDXTag name="div" components={components} parentName="h1" props={{"class":"hidden-anchor","id":"get-help"}}></MDXTag>{`Get Help`}</MDXTag>
<MDXTag name="p" components={components}>{`Email `}<MDXTag name="a" components={components} parentName="p" props={{"href":"mailto:help@totogi.com"}}>{`help@totogi.com`}</MDXTag>{` with your questions or create a Ticket through our `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://support.totogi.com/"}}>{`Help Desk`}</MDXTag>{` using your support credentials and we’ll get someone to assist you shortly.`}</MDXTag>
           </MDXTag>
  }
}
MDXContent.isMDXComponent = true
  